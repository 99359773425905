import React from 'react';
import {
  func, shape, string, bool, number
} from 'prop-types';
import { VideoPlayer } from '@thd-olt-component-react/video-player';
import { Image } from '@thd-olt-component-react/core-ui';
import classNames from 'classnames';
import Helper from '../../Helper';
import Threesixty from '../Threesixty';
import { HotspotTagIcon } from '../Icons/HotspotTagIcon.js';
import { AvataarIFrame } from '../MediaGalleryOverlay/components/AvataarIFrame/AvataarIFrame';
import { dataModel } from '../../dataModel';

export const InteractiveMediaOverlay = ({
  mainImage, onClose, videoAnalyticsData, showAvataarDefault, showAvataarAR, itemId, selectedImageIndex
}) => {
  const isVideo = Helper.isVideo({ media: mainImage });
  const isImage360 = Helper.isImage360({ media: mainImage });
  const isHotspot = Helper.isHotspot({ media: mainImage });
  const { source, videoSource, videoId } = Helper.formatVideoProps(mainImage);
  const avataarExperienceLink = Helper.getAvataarExperienceLink(itemId, showAvataarAR);
  const mainContentClasses = classNames(
    'overlay-mobile__main-content',
    'overlay-mobile__main-content-landscape',
    { 'overlay-mobile__main-content-landscape__video': isVideo }
  );

  return (
    <div className="overlay-mobile" data-component="InteractiveMediaOverlay">
      <section className="overlay-mobile__wrapper">
        <div className="overlay-mobile__main-content">
          <button type="button" className="overlay-mobile__close-button" onClick={onClose}>
            <Image
              className="overlay-mobile__close"
              asset="close-symbol-orange.svg"
              alt="close"
              lazy
            />
          </button>
        </div>
        {(isImage360 || isVideo || isHotspot)
          && (
            <div className={mainContentClasses}>
              {isImage360 && (<Threesixty media={mainImage} />)}
              {isVideo
              && (
                <VideoPlayer
                  videoId={videoId}
                  source={source}
                  videoSrc={videoSource}
                  analyticsData={videoAnalyticsData}
                  autoPlay
                />
              )}
              {isHotspot
                && (
                  <HotspotTagIcon
                    mediaGalleryIndex={selectedImageIndex}
                    openOverlay={false}
                    hotspots={mainImage.hotspots}
                    ocrHotspots={mainImage.ocrHotspots}
                    imageUrl={mainImage.url}
                  />
                )}
            </div>
          )}

        {
          (showAvataarDefault || showAvataarAR)
          && avataarExperienceLink
          && <AvataarIFrame key="interactive-media-overlay" avataarExperienceLink={avataarExperienceLink} />
        }
      </section>
    </div>
  );
};

InteractiveMediaOverlay.displayName = 'InteractiveMediaOverlay';

InteractiveMediaOverlay.propTypes = {
  onClose: func.isRequired,
  mainImage: shape({}).isRequired,
  videoAnalyticsData: shape({
    section: string.isRequired,
    component: string.isRequired,
    position: string.isRequired
  }).isRequired,
  showAvataarDefault: bool.isRequired,
  showAvataarAR: bool.isRequired,
  itemId: string.isRequired,
  selectedImageIndex: number.isRequired
};

InteractiveMediaOverlay.dataModel = dataModel;
